import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NewsServiceService } from '../../news-service.service';
import { News } from '../../news';
@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss']
})

export class ProfileComponent implements OnInit {
    article_url;
    private sub: any;
    resdata;
    loaddata = false;
    recentdata = [];
    loadrecentdata = false;
    constructor(public NewsServiceService: NewsServiceService, private route: ActivatedRoute) { }

    ngOnInit() {
        this.route.params.subscribe(params => {
            this.article_url = params['id'];
            this.NewsServiceService.getNewsArticle(this.article_url).subscribe((data: News[]) => {
                this.resdata = data[0];
                this.loaddata = true;
                var url_val = "https://buruju.com/news/" + this.resdata.article_url;
                this.NewsServiceService.updateDescription(this.resdata.articletitle, this.resdata.articlecontent, this.resdata.author, url_val, this.resdata.category, this.resdata.image);
            }, error => { console.log('Something went wrong!', error) });
        }, error => { console.log('Something went wrong!', error) });
        this.NewsServiceService.getRecentArticles().subscribe((data: News[]) => {
            this.recentdata = data;
            this.loadrecentdata = true;
        });
    }
    getTagName(val) {
        return this.NewsServiceService.getTagName(val);
    }
    ngAfterViewInit() {
        setTimeout(() => {
            try {
                (window['adsbygoogle'] = window['adsbygoogle'] || []).push({});
            } catch (e) { }
        }, 3000);
    }
}
