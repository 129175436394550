import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Meta, Title } from '@angular/platform-browser';
@Injectable({
  providedIn: 'root'
})
export class NewsServiceService {
  artValue: any;
  userdata = [];
  apiurl = "https://buruju.com"
  public lat = 17.4146631;
  public lng = 78.355143;
  locAllowed = false;
  panchData = null;
  tagValues =
    {
      "charitrakam": "చారిత్రకం",
      "ardikam": "ఆర్థికం",
      "reporterdairy": "రిపోర్టర్ డైరీ",
      "avievi": "అవీ ఇవీ",
      "chalanachirtram": "చలన చిత్రం",
      "samajikam": "సామాజికం",
      "udyogam": "ఉద్యోగం",
      "graminam": "గ్రామీణం",
      "adarsham": "ఆదర్శం",
      "rajakiyam": "రాజకీయం",
      "all": "అన్ని"
    }
    sideAds = true;
    otherpageAds = false;
    recentSectionAds = true;
    toppageAds = true;
    ad300x150 = true;
  constructor(private title: Title, private meta: Meta, private http: HttpClient) {
    this.getLocation();
  }
  getNews_avievi() {
    return this.http.get<any[]>(this.apiurl + '/newspaper_api/news/getAviEvi.php');
  }
  getNews_samajikam() {
    return this.http.get<any[]>(this.apiurl + '/newspaper_api/news/getSamajikam.php');
  }
  getNews_ardikam() {
    return this.http.get<any[]>(this.apiurl + '/newspaper_api/news/getArdikam.php');
  }
  getNews_charitrakam() {
    return this.http.get<any[]>(this.apiurl + '/newspaper_api/news/getCharitrakam.php');
  }
  getNews_udhyogam() {
    return this.http.get<any[]>(this.apiurl + '/newspaper_api/news/getUdhyogam.php');
  }
  getNews_graminam() {
    return this.http.get<any[]>(this.apiurl + '/newspaper_api/news/getGraminam.php');
  }
  getNews_allNews() {
    return this.http.get<any[]>(this.apiurl + '/newspaper_api/news/getAllNews.php');
  }
  getNews_adarsham() {
    return this.http.get<any[]>(this.apiurl + '/newspaper_api/news/getAdarsham.php');
  }
  getNews_reporterdairy() {
    return this.http.get<any[]>(this.apiurl + '/newspaper_api/news/getReporterDairy.php');
  }
  getNews_chalanachitram() {
    return this.http.get<any[]>(this.apiurl + '/newspaper_api/news/getChalanaChitram.php');
  }
  saveContactinformation(data) {

    return this.http.post<any[]>(this.apiurl + '/newspaper_api/saveContactInfo.php', data);
  }
  getNewsCarosel() {
    return this.http.get<any[]>(this.apiurl + '/newspaper_api/listcarosel.php');
  }
  getNewsCarosel2() {
    return this.http.get<any[]>(this.apiurl + '/newspaper_api/listcarosel2.php');
  }
  saveArticle(val) {
    return this.http.post<any[]>(this.apiurl + '/newspaper_api/saveArticle.php', val);
  }
  delArticle(val: number) {
    return this.http.delete<any[]>(this.apiurl + '/newspaper_api/delArticle.php?idno=' + val);
  }
  setEditArticle(obj) {
    this.artValue = obj;
  }
  getEditArticle() {
    return this.artValue;
  }
  getEditArticleData(val) {
    return this.http.get<any[]>(this.apiurl + '/newspaper_api/getEditArticleData.php?idno=' + val);
  }
  saveEditedArticle(val) {
    return this.http.post<any[]>(this.apiurl + '/newspaper_api/saveEditedArticle.php', val);
  }
  setLoginUserData(obj) {
    this.userdata = obj;
  }
  getLoginUserData() {
    return this.userdata;
  }
  validatelogin(val) {
    return this.http.post<any[]>(this.apiurl + '/newspaper_api/validatelogin.php', val);
  }
  getcoronadata() {
    return this.http.get<any[]>('https://api.covid19api.com/summary');
  }
  getNewsArticle(val) {
    return this.http.get<any[]>(this.apiurl + '/newspaper_api/getNewsArticle.php?article_url=' + val);
  }
  getRecentArticles() {
    return this.http.get<any[]>(this.apiurl + '/newspaper_api/getRecentArticles.php');
  }
  getAllArticles() {
    return this.http.get<any[]>(this.apiurl + '/newspaper_api/news/list.php');
  }
  getWeather(lat, long) {
    return this.http.get<any>('https://api.weatherapi.com/v1/current.json?key=efb4334878af49c4818171045222905&q=' + lat + ',' + long + '&aqi=no');
  }
  getLocation() {
    // if (navigator.geolocation) {
    //   navigator.geolocation.getCurrentPosition((position) => {
    //     if (position) {
    //       console.log("Latitude: " + position.coords.latitude +
    //         "Longitude: " + position.coords.longitude);
    //       this.lat = position.coords.latitude;
    //       this.lng = position.coords.longitude;
    //       // console.log(this.lat);
    //       // console.log(this.lng);
    //       this.locAllowed = true;
    //     }
    //   },
    //     (error) => (error.message)
    //     );
    // } else {
    //   console.log("Geolocation is not supported by this browser.");
    // }
    return { "lat": this.lat, "lng": this.lng, "locAllowed": this.locAllowed };
  }
  getLocationValue() {
    return { "lat": this.lat, "lng": this.lng };
  }
  updateDescription(title, desc, author, url, keywords, imageurl) {
    this.title.setTitle(title);
    this.meta.updateTag({ name: 'description', content: desc });
    this.meta.addTag({ property: 'keywords', content: this.getTagName(keywords) });
    this.meta.addTag({ property: 'author', content: author });

    this.meta.updateTag({ property: 'og:title', content: title });
    this.meta.updateTag({ property: 'og:url', content: url });
    this.meta.updateTag({ property: 'og:description', content: desc });
    this.meta.updateTag({ property: 'og:image', content: imageurl });
    this.meta.addTag({ property: 'og:keywords', content: this.getTagName(keywords) });
    this.meta.addTag({ property: 'og:image:url', content: imageurl });
    this.meta.addTag({ property: 'og:image:width', content: '500' });
    this.meta.addTag({ property: 'og:image:height', content: '500' });
    this.meta.addTag({ property: 'og:type', content: 'News' });
    this.meta.addTag({ property: 'og:site_name', content: 'https://www.buruju.com' });

    this.meta.addTag({ property: 'twitter:image', content: imageurl });
    this.meta.addTag({ property: 'twitter:card', content: 'summary_large_image' });
    this.meta.addTag({ property: 'twitter:site', content: '@BurujuNews' });
    this.meta.addTag({ property: 'twitter:creator', content: author });
  }
  getTagName(val) {
    return this.tagValues[val];
  }
  getVaccineData() {
    return this.http.get<any>('https://cdn-api.co-vin.in/api/v1/reports/v2/getPublicReports?state_id=&district_id=&date=');
  }
  getStocksData(indices) {
    let headers = new HttpHeaders();
    headers = headers.set('x-rapidapi-key', '3da5a84767msh3ef22c451bbb98fp1cdb4djsncdcc56cea7a2').set('x-rapidapi-host', 'latest-stock-price.p.rapidapi.com');
    return this.http.get<any>('https://latest-stock-price.p.rapidapi.com/price?Indices=' + indices, { headers: headers });

  }
  getDetails() {
    return this.http.get<any>('https://ipapi.co/json/');
  }
  saveCustomerDetails(val) {
    val["date"] = new Date().getDate();
    val["serviceprovider"] = val.org;
    // var newval = btoa(val);
    // console.log(newval);
    return this.http.post<any[]>(this.apiurl + '/newspaper_api/news/saveCustomerDetails.php', val);
  }
  getPanchangToken() {
    let formData: FormData = new FormData();
    formData.append('grant_type', 'client_credentials');
    formData.append('client_id', '0d493e3f-31a0-47bd-95a8-e6b8aa48e216');
    formData.append('client_secret', 'q4F7z2bQRHufce0aXJV77aRkaE8WkoLd3J100v8H');
    return this.http.post<any[]>('https://api.prokerala.com/token', formData);
  }
  getPanchang(token) {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', 'Bearer' + token);

    var url = "https://api.prokerala.com/v2/astrology/panchang?ayanamsa=1&coordinates=23.1765,75.7885&datetime=2022-03-17T10:50:40%2B00:00"
    return this.http.get<any>(url, { headers: headers });
  }
  getpanchangResData() {
    return this.panchData;
  }
  setpanchangResData(data) {
    this.panchData = data;
  }
}
