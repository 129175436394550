<div id="header" class="wrapper">
    <div class="filter"></div>
    <div class="section profile-content">
        <div class="container">
            <div style="margin-top:5em;"></div>
            <br />
            <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutGap="12px" fxLayoutAlign="flex-start">
                <mat-card *ngIf="loaddata" style="padding:5px;" fxFlex="0 1 calc(100% - 0px)"
                    fxFlex.lt-md="0 1 calc(75% - 32px)" fxFlex.lt-sm="100%">
                    <div id="bannerFull">
                        <ng-adsense [adClient]="'ca-pub-6715553274893209'" [adSlot]="3991352689"
                            [display]="'inline-block'" [width]="728" [height]="90"></ng-adsense>
                    </div>
                    <div id="bannerMobile">
                        <ng-adsense [adClient]="'ca-pub-6715553274893209'" [adSlot]="3991352689"
                            [display]="'inline-block'" [width]="300" [height]="250"></ng-adsense>
                    </div>
                    <mat-divider [inset]="true"></mat-divider>
                    <div class="search-hero">
                        <input class="form-control" type="text" name="search" [(ngModel)]="searchText"
                            autocomplete="off" placeholder="&#61442;  Search in telugu">
                    </div>
                    <div *ngFor="let i of searchdata | filter:searchText">
                        <div fxLayout="row" fxLayoutAlign="flex-start" style="padding:10px 5px 0px 2px;">
                            <div style="cursor: pointer;" [routerLink]="['/news', i.article_url]">
                                <img src="{{i.image}}" alt="{{i.articletitle}}" width="110" height="110">
                            </div>
                            <div style="margin-left:5px">
                                <a [routerLink]="['/news', i.article_url]"
                                    style="color:black;text-decoration: none;font-size: 20px;"><span
                                        style="margin-bottom: 5px;font-weight: 600;">{{i.articletitle}}</span><br><br>
                                    <div *ngIf="i.articlecontent.length<=120" [routerLink]="['/news', i.article_url]"
                                        style="color:rgb(73, 70, 70);text-decoration: none;font-size: 14px;">
                                        {{i.articlecontent}}
                                    </div>
                                    <div *ngIf="i.articlecontent.length>120" [routerLink]="['/news', i.article_url]"
                                        style="color:rgb(73, 70, 70);text-decoration: none;font-size: 14px;">
                                        {{i.articlecontent |
                                        slice:0:120}}...</div>
                                </a>
                                <div class="icon-text" style="font-size: 12px;position: relative;">
                                    <span class="material-icons" style="font-size: 17px;">person</span><span
                                        style="padding:2px;">{{i.author}}</span>
                                    <span class="material-icons" style="font-size: 17px;">date_range</span>
                                    <span style="padding:8px;">{{i.date}}</span>
                                </div>
                            </div>
                        </div>
                        <mat-divider [inset]="true"></mat-divider>
                    </div>
                </mat-card>
                <!-- <mat-card class="example-card card card-stats" fxFlex="0 1 calc(25% - 12px)"
                    fxFlex.lt-md="0 1 calc(25% - 12px)" fxFlex.lt-sm="100%">
                    <mat-card-content>
                        <h3>Recent Posts:</h3>
                        <mat-divider [inset]="true"></mat-divider>
                        <div *ngFor="let i of recentdata">
                            <div fxLayout="row" fxLayoutAlign="flex-start" style="padding:5px 5px 0px 2px;">
                                <div>
                                    <img src="{{i.image}}" alt="{{i.articletitle}}" width="70" height="70">
                                </div>
                                <div style="margin-left:5px">
                                    <a [routerLink]="['/news', i.article_url]"
                                        style="color:black;text-decoration: none;font-size: 16px;">{{i.articletitle}}</a>
                                    <div class="icon-text" style="font-size: 14px;">
                                        <span class="material-icons" style="font-size: 17px;"></span><span
                                            style="padding:5px;"
                                            class="label label-info">{{getTagName(i.category)}}</span>
                                    </div>
                                </div>
                            </div>
                            <mat-divider [inset]="true"></mat-divider>
                        </div>
                    </mat-card-content>
                </mat-card> -->
            </div>
        </div>
    </div>
</div>
<div>
    <div id="FloatAdx-left" *ngIf="NewsServiceService.sideAds">
        <ng-adsense [adClient]="'ca-pub-6715553274893209'" [adSlot]="3991352689" [display]="'inline-block'"
            [width]="160" [height]="600"></ng-adsense>
    </div>
    <div id="FloatAdx-right" *ngIf="NewsServiceService.sideAds">
        <ng-adsense [adClient]="'ca-pub-6715553274893209'" [adSlot]="3991352689" [display]="'inline-block'"
            [width]="160" [height]="600"></ng-adsense>
    </div>
</div>