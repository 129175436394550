<div class="wrapper container" style="margin-top:4.5em;">
  <div id="bannerFull" *ngIf="NewsServiceService.otherpageAds">
    <ng-adsense [adClient]="'ca-pub-6715553274893209'" [adSlot]="3991352689" [display]="'inline-block'" [width]="728"
      [height]="90"></ng-adsense>
  </div>
  <div id="bannerMobile" *ngIf="NewsServiceService.otherpageAds">
    <ng-adsense [adClient]="'ca-pub-6715553274893209'" [adSlot]="3991352689" [display]="'inline-block'" [width]="300"
      [height]="250"></ng-adsense>
  </div>
  <main class="profile-page">
    <section class="section">
      <div class="container">
        <h1 style="text-align: left;"><b>ABOUT US</b></h1>
        <div class="card card-profile shadow" style="margin-top:0px;">
          <div class="px-4">
            <div class="row justify-content-center">
              <div class="col-lg-3 order-lg-2">
                <div class="card-profile-image">
                    <img src="https://buruju.com/images/buruju-logo1.png" alt="Burju.com Logo" style="height:80px;">
                </div>
              </div>
              <div class="col-lg-4 order-lg-3 text-lg-right align-self-lg-center">
                <div class="card-profile-actions py-4 mt-lg-0">
                </div>
              </div>
              <div class="col-lg-4 order-lg-1">

              </div>
            </div>
            <div class="text-center mt-1">
              <h3>Pilla Sai Kumar
              </h3>
              <div class="h6 mt-1"><i class="ni business_briefcase-24 mr-2"></i>Cheif Editor - Buruju.com</div>
              <div><i class="ni education_hat mr-2"></i>Former Prinicipal correspondent - EENADU</div>
              <br>
              <p>Pilla Sai Kumar worked as a Prinicipal correspondent with the EENADU, telugu daily for a period of 36
                years. During this long time, he worked in various places like Saravakota, Vizianagaram, Visakhapatnam,
                Eluru, Sangareddy, Nalgonda and Hyderabad. He has written several news items and articles on various
                topics. Any suggestion or feedback kindly write us at:</p>
              <p style="font-weight: bold;">editor@buruju.com</p>

            </div>
            <div class="mt-2 py-5 border-top text-center">
              <div class="row justify-content-center">
                <div class="col-lg-9">
                  <p>Buruju News (buruju.com), the online telugu portal brings you weekly news and views mainly focusing on Social,
                    Financial and Historical events of Andhra Pradesh and Telangana states from Hyderabad, India.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</div>