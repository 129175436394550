<div class="wrapper container" style="margin-top:4.5em;">
    <div id="bannerFull" *ngIf="NewsServiceService.otherpageAds">
        <ng-adsense [adClient]="'ca-pub-6715553274893209'" [adSlot]="3991352689" [display]="'inline-block'" [width]="728"
          [height]="90"></ng-adsense>
      </div>
      <div id="bannerMobile" *ngIf="NewsServiceService.otherpageAds">
        <ng-adsense [adClient]="'ca-pub-6715553274893209'" [adSlot]="3991352689" [display]="'inline-block'" [width]="300"
          [height]="250"></ng-adsense>
      </div>
    <main class="profile-page">
        <section class="section">
            <div class="container">
                <h1 style="text-align: left;"><b>DISCLAIMER</b></h1>
                <div class="card card-profile shadow" style="margin-top:0px;">
                    <div class="px-4" style="text-align: justify;">
                        <div class="row justify-content-center">
                            <div class="col-lg-3 order-lg-2">
                                <div class="card-profile-image">
                                        <img src="https://buruju.com/images/buruju-logo1.png" style="height:80px;">
                                </div>
                            </div>
                            <div class="col-lg-4 order-lg-3 text-lg-right align-self-lg-center">
                                <!-- <div class="card-profile-actions py-4 mt-lg-0">
                                    <a href="https://buruju.com/contact" class="btn btn-sm btn-info mr-4" target="_blank">Contact</a>
                                    <a href="https://buruju.com/feedback"
                                        class="btn btn-sm btn-default float-right" target="_blank">Feedback</a>
                                </div> -->
                            </div>
                            <div class="col-lg-4 order-lg-1">

                            </div>
                        </div>
                        EXCEPT FOR THE EXPRESS WARRANTIES STATED HEREIN, THE SITE/ SERVICE IS PROVIDED ON AN "AS IS"
                        BASIS, AND BURUJU DISCLAIMS ANY AND ALL OTHER WARRANTIES, CONDITIONS, OR REPRESENTATIONS
                        (EXPRESS, IMPLIED, ORAL OR WRITTEN), RELATING TO THE SITE/ SERVICE OR ANY PART THEREOF,
                        INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
                        PARTICULAR PURPOSE, WORKMANLIKE EFFORT, TITLE AND NON-INFRINGEMENT ARE DISCLAIMED AND EXCLUDED.
                        BURUJU AND ITS PARENT, AFFILIATES AND ASSOCIATES SHALL NOT BE LIABLE, AT ANY TIME FOR ANY,
                        DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL, DAMAGES (INCLUDING, WITHOUT
                        LIMITATION, DAMAGES FOR LOSS OF BUSINESS PROJECTS, DAMAGE TO YOUR COMPUTER SYSTEM OR DAMAGES FOR
                        LOSS OF PROFITS, DAMAGES FOR LOSS OF USE, DATA OR PROFITS, ARISING OUT OF OR IN ANY WAY
                        CONNECTED WITH THE USE OR PERFORMANCE OF THE BURUJU SITES/SERVICES, WITH THE DELAY OR INABILITY
                        TO USE THE BURUJU SITES/SERVICES OR RELATED SERVICES, THE PROVISION OF OR FAILURE TO PROVIDE
                        SERVICES, OR FOR ANY INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS OBTAINED
                        THROUGH THE BURUJU SITES/SERVICES, OR OTHERWISE ARISING OUT OF THE USE OF THE BURUJU
                        SITES/SERVICES) ARISING IN CONTRACT, TORT OR OTHERWISE FROM THE USE OF OR INABILITY TO USE THE
                        SITE, OR ANY OF ITS CONTENTS, OR FROM ANY ACT OR OMISSIONS A RESULT OF USING THE SITE OR ANY
                        SUCH CONTENTS OR FOR ANY FAILURE OF PERFORMANCE, ERROR, OMISSION, INTERRUPTION, DELETION,
                        DEFECT, DELAY IN OPERATION OR TRANSMISSION, COMPUTER VIRUS, COMMUNICATIONS LINE FAILURE, THEFT
                        OR DESTRUCTION OR UNAUTHORISED ACCESS TO, ALTERATION OF, OR USE OF INFORMATION CONTAINED ON THE
                        SITE. NO REPRESENTATIONS, WARRANTIES OR GUARANTEES WHATSOEVER ARE MADE BY BURUJU AS TO THE (A)
                        ACCURACY, ADEQUACY, RELIABILITY, COMPLETENESS, SUITABILITY OR APPLICABILITY OF THE INFORMATION
                        TO A PARTICULAR SITUATION; (B) THAT THE SERVICE WILL BE UNINTERRUPTED, TIMELY, SECURE, OR
                        ERROR-FREE; (C) THE QUALITY OF ANY PRODUCTS, SERVICES, CONTENT, INFORMATION, OR OTHER MATERIAL
                        PURCHASED OR OBTAINED FROM THE WEBSITE WILL MEET YOUR EXPECTATIONS OR REQUIREMENTS; OR (D) ANY
                        ERRORS IN THE WEBSITE WILL BE CORRECTED.<br><br>
                        <b>Links to Other Sites.</b><br><br>
                        All the contents of this Site are only for general information or use. They do not constitute
                        advice and should not be relied upon in making (or refraining from making) any decision. Any
                        specific advice or replies to queries in any part of the Site is/are the personal opinion of
                        such experts/consultants/persons and are not subscribed to by this Site. The information from or
                        through this site is provided on "AS IS" basis, and all warranties and conditions, expressed or
                        implied of any kind, regarding any matter pertaining to any goods, service or channel, including
                        without Certain links on the Site lead to resources located on servers maintained by third
                        parties, these sites of third party(s) may contain BURUJU-logo, please understand that it is
                        independent from BURUJU, over whom BURUJU has no control or connection, business or otherwise as
                        these sites are external to BURUJU. You agree and understand that by visiting such sites you are
                        beyond the BURUJU website. BURUJU, therefore neither endorses nor offers any judgement or
                        warranty and accepts no responsibility or liability for the authenticity, availability,
                        suitability, reliability, accuracy of the information, software, products, services and related
                        graphics contained, of any of the goods/services/or for any damage, loss or harm, direct or
                        consequential or any violation of local or international laws that may be incurred by your visit
                        and/or transaction/s on these site(s), as the same is provided on "as is" without warranty of
                        any kind. BURUJU gives no warranty and makes no representation whether expressed or implied,
                        that the information contained in this site is error free. BURUJU shall not be responsible nor
                        liable for any consequential damages arising on account of your relying on the contents of the
                        advertisement. Before relying on the material, users should independently verify its relevance
                        for their purposes, and should obtain any appropriate professional advice.<br><br>
                        <b>Third Party Content</b><br><br>
                        The information, content and material made available on this website is intended strictly for
                        informational purposes and is provided on an “as-is” & “as available” basis. While, we take
                        reasonable steps to verify the accuracy of the information and data presented on the website
                        including our news stories and articles, we do not make any (and hereby disclaims all)
                        representations and warranties regarding the accuracy, completeness, fitness of such information
                        and data especially any third party information and data used or referred to on the website. We
                        may use or refer to information and/or data in our news stories, articles or reports from third
                        party news agencies and online media and we shall not be liable for any delays, inaccuracies,
                        errors or omissions in any such content, or in the transmission or delivery of all or any part
                        thereof, or for any damages arising therefrom.<br><br>
                        THE WEBSITE AND/OR BURUJU WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE ARISING DIRECTLY OR
                        INDIRECTLY (INCLUDING SPECIAL, INCIDENTAL, PUNITIVE OR CONSEQUENTIAL LOSS OR DAMAGE) FROM YOUR
                        USE OF THIS WEBSITE HOWSOEVER ARISING, AND INCLUDING ANY LOSS, DAMAGE OR EXPENSE ARISING FROM
                        BUT NOT LIMITED TO ANY DEFECT, ERROR, IMPERFECTION, FAULT, MISTAKE OR INACCURACY WITH THIS
                        WEBSITE, ITS CONTENTS, ANY THIRD PARTY CONTENT OR ASSOCIATED SERVICES OR DUE TO ANY
                        UNAVAILABILITY OF THE WEBSITE OR ANY PART THEREOF OR ANY CONTENTS.<br><br>
                    </div>
                </div>
            </div>
        </section>
    </main>
</div>