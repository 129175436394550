import { Component, OnInit } from '@angular/core';
import { NewsServiceService } from '../../news-service.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {

  constructor(public NewsServiceService: NewsServiceService) { }

  ngOnInit(): void {
  }
  ngAfterViewInit() {
    setTimeout(() => {
        try {
            (window['adsbygoogle'] = window['adsbygoogle'] || []).push({});
        } catch (e) { }
    }, 3000);
}
}
