import { Component, OnInit } from '@angular/core';
import { NewsServiceService } from '../../news-service.service';

@Component({
  selector: 'app-privacypolicy',
  templateUrl: './privacypolicy.component.html',
  styleUrls: ['./privacypolicy.component.css']
})
export class PrivacypolicyComponent implements OnInit {

  constructor(public NewsServiceService: NewsServiceService) { }

  ngOnInit(): void {
  }
  ngAfterViewInit() {
    setTimeout(() => {
        try {
            (window['adsbygoogle'] = window['adsbygoogle'] || []).push({});
        } catch (e) { }
    }, 3000);
}
}
