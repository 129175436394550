import { Component, OnInit } from '@angular/core';
import { NewsServiceService } from '../../news-service.service';
import { News } from '../../news';

@Component({
  selector: 'app-searchburuju',
  templateUrl: './searchburuju.component.html',
  styleUrls: ['./searchburuju.component.scss']
})
export class SearchBurujuComponent implements OnInit {
  article_url;
  private sub: any;
  resdata;
  loaddata = false;
  recentdata = [];
  loadrecentdata = false;
  searchdata = [];
  searchText;
  p;
  constructor(public NewsServiceService: NewsServiceService) { }

  ngOnInit() {
    this.NewsServiceService.getAllArticles().subscribe((data: News[]) => {
      this.searchdata = data;
      this.loaddata = true;
    }, error => { console.log('Something went wrong!', error) });
    this.NewsServiceService.getRecentArticles().subscribe((data: News[]) => {
      this.recentdata = data;
      this.loadrecentdata = true;
    }, error => { console.log('Something went wrong!', error) });
  }
  getTagName(val) {
    return this.NewsServiceService.getTagName(val);
  }
  ngAfterViewInit() {
    setTimeout(() => {
        try {
            (window['adsbygoogle'] = window['adsbygoogle'] || []).push({});
        } catch (e) { }
    }, 3000);
}
}
