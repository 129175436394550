import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { ComponentsComponent } from './components/components.component';
import { ProfileComponent } from './components/profile/profile.component';
import { AboutComponent } from './components/about/about.component';
import { ContactComponent } from './components/contact/contact.component';
import { PrivacypolicyComponent } from './components/privacypolicy/privacypolicy.component';
import { SearchBurujuComponent } from './components/searchburuju/searchburuju.component';
import { TermsandconditionsComponent } from './components/termsandconditions/termsandconditions.component';
import { DisclaimerComponent } from './components/disclaimer/disclaimer.component';
import { FeedbackComponent } from './components/feedback/feedback.component';

const routes: Routes =[
    { path: '', redirectTo: 'home', pathMatch: 'full' },
    { path: 'home',             component: ComponentsComponent},
    { path: 'news/:id',     component: ProfileComponent },
    { path: 'news',     component: SearchBurujuComponent },
    { path: 'about',      component: AboutComponent },
    { path: 'contact',      component: ContactComponent },
    { path: 'privacypolicy',      component: PrivacypolicyComponent },
    { path: 'searchburuju',      component: SearchBurujuComponent },
    { path: 'disclaimer',      component: DisclaimerComponent },
    { path: 'termsandconditions',      component: TermsandconditionsComponent },
    { path: 'feedback',      component: FeedbackComponent },


];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes,{
      useHash: false,
      scrollPositionRestoration: 'enabled' //scroll to the top
    })
  ],
  exports: [
  ],
})
export class AppRoutingModule { }
