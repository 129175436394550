<!-- Site footer -->
<footer id="header" class="site-footer" style="color:white;">
  <div class="container">
    <div class="row">
      <div class="col-sm-12 col-md-6">
        <h6>About</h6>
        <p>Buruju.com, the online telugu portal brings you weekly news and views mainly focusing on Social, Financial
          and Historical events of Andhra Pradesh and Telagana states from Hyderabad, India.</p>
      </div>
      <div class="col-xs-6 col-md-3">
        <h6>Contact</h6>
        For Editorial feedback and Marketing Contact:<Br>
        <p>editor@buruju.com</p>
      </div>
      <div class="col-xs-6 col-md-3">
        <h6>Quick Links</h6>
        <ul>
          <li><a href="https://buruju.com/about" target="_blank">About Us</a></li>
          <li><a href="https://buruju.com/contact" target="_blank">Contact Us</a></li>
          <li><a href="https://buruju.com/searchburuju" target="_blank">Search Buruju</a></li>
          <li><a href="https://buruju.com/privacypolicy" target="_blank">Privacy Policy</a></li>
          <li><a href="https://buruju.com/termsandconditions" target="_blank">Terms & Conditions</a></li>
          <li><a href="https://buruju.com/disclaimer" target="_blank">Disclaimer</a></li>
        </ul>
      </div>
    </div>
    <hr>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-md-10 col-sm-6 col-xs-12">
        <p class="copyright-text">Copyright &copy; 2020 All Rights Reserved by
          <a href="https://buruju.com">Buruju</a>. Contents of ‘Buruju.com’ are copyright protected. Copy or
          reproduction or re use of contents or any part thereof is illegal. Such persons will be prosecuted.
        </p>
      </div>
      <div class="col-md-2 col-sm-6 col-xs-12">
        <ul class="social-icons">
          <li><a class="twitter" style="color:#00aced;" href="https://twitter.com/BurujuNews" target="_blank"><i
                class="fa fa-twitter"></i></a></li>
        </ul>
      </div>
    </div>
  </div>
</footer>