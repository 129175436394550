<div class="wrapper container" style="margin-top:4.5em;">
  <div id="bannerFull" *ngIf="NewsServiceService.otherpageAds">
    <ng-adsense [adClient]="'ca-pub-6715553274893209'" [adSlot]="3991352689" [display]="'inline-block'" [width]="728"
      [height]="90"></ng-adsense>
  </div>
  <div id="bannerMobile" *ngIf="NewsServiceService.otherpageAds">
    <ng-adsense [adClient]="'ca-pub-6715553274893209'" [adSlot]="3991352689" [display]="'inline-block'" [width]="300"
      [height]="250"></ng-adsense>
  </div>
  <main class="profile-page">
    <section class="section">
      <div class="container">
        <h1 style="text-align: left;"><b>PRIVACY POLICY</b></h1>
        <div class="card card-profile shadow" style="margin-top:0px;">
          <div class="px-4" style="text-align: justify;">
            <div class="row justify-content-center">
              <div class="col-lg-3 order-lg-2">
                <div class="card-profile-image">
                    <img src="https://buruju.com/images/buruju-logo1.png" style="height:80px;">
                </div>
              </div>
              <div class="col-lg-4 order-lg-3 text-lg-right align-self-lg-center">
                <!-- <div class="card-profile-actions py-4 mt-lg-0">
                    <a href="https://buruju.com/contact" class="btn btn-sm btn-info mr-4" target="_blank">Contact</a>
                    <a href="https://buruju.com/feedback"
                        class="btn btn-sm btn-default float-right" target="_blank">Feedback</a>
                </div> -->
            </div>
              <div class="col-lg-4 order-lg-1">

              </div>
            </div>


            <strong>buruju.com</strong> is a news portal of Buruju, aimed at providing latest news and current affairs. We understand the
            importance of your right to privacy, hence have elicited the below privacy statement with regard to the
            information we collect from you. If you have questions or concerns regarding this statement, please email to us on
            editor@buruju.com. <br>
            <br>
            buruju.com provides a user-friendly platform for our members to access our content. <br>
            The information collected from any of our members is not shared with any individual or organization without
            the formers approval. <br> <br>

            All buruju.com visitors will be able to browse the site, search member profiles and view any
            articles or features posted on our site, without entering any personal information. <br> <br>
            Some of the essential information gathered from members and guests who intend to access content and avail
            various services on Buruju.com includes, but may not be limited to, email address, first name, last name,
            age, religion, a user-specified password, mailing address, pin code and mobile number. <br> <br>
             <br> <br>
            The information gathered from buruju.com members is shared with any individual or organization only with the
            consent of the member. <br> <br>
            Except where members are expressly informed, buruju.com does not sell, rent, or loan any identifiable
            information at the individual level regarding its customers to any third party. All information provided by
            members is held with the utmost care and security. However, buruju.com is also bound to cooperate fully
            should a situation arise where we are required by law or legal process to provide information about a
            customer. <br> <br>

            To extend the benefits and services on buruju.com, we may tie-up with third parties/partners with whom we
            share your personal information as may be necessary. <br> <br>
            <strong>Links to external websites: </strong><br>
            buruju.com may contain links to external websites. Please be aware that buruju.com does not control the
            content of such sites and/or the privacy policy of such external sites and shall not be responsible for the
            privacy practices of external Web sites. We request you to be fully aware of this when you leave buruju.com.
            Do read the privacy statements of each and every web site that collects personally identifiable information.
            <br> <br>

            This privacy statement applies solely to information collected by buruju.com<br> <br>
            <strong>Cookies on buruju.com: </strong><br>
            Cookies on buruju.com are used to deliver various services and keep track of your personal preferences. If
            you do not want cookies to collect information on buruju.com, most browsers contain a function that allows a
            user to deny cookies on websites. Alternatively you can also delete all cookie files on your computer.
            Please note that disabling the cookie feature on your browser or deleting cookie files from your computer
            will render you unable to access certain features on buruju.com, as the use of cookies is a necessary part
            of the buruju.com net technology. buruju.com uses cookies only to: <br> <br>
            • Provide its services<br> <br>
            • Deliver content specific to your interests<br> <br>

            Please note: Cookies are used only to recollect information sent to your computer from buruju.com. We CANNOT
            access any information not sent by buruju.com. Some of our partners may use cookies on our site (for
            example, Google advertisements). <br> <br>
            However, we have no access to or control over these cookies. Changes in buruju.com privacy policy: UEPL
            reserves right to amend the privacy policy of buruju.com. Users are advised to periodically check our
            privacy policy for any amends. <br> <br>
            Apart from using SSL encryption, buruju.com takes every measure in its power to protect sensitive
            information <br> <br>


            <strong>Contact buruju.com</strong><br>
            If you have any questions about this privacy statement, the practices of buruju.com, or your dealings with
            this Web site, please email us at: editor@buruju.com<br><br>
          </div>
        </div>
      </div>
    </section>
  </main>
</div>